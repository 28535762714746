export const geraAgenda = (dataInicial) => {

    let id = 0;
    let agenda = [];
    let semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];

    const preencheAgenda = (data) => {
        let diaSemana = new Date(data).getDay();
        let dia = semana[diaSemana];
      
        if (diaSemana === 2 || diaSemana === 4) {
            agenda.push(
                { id: id++, data: data, dia: dia, hora: '10:00' },
                { id: id++, data: data, dia: dia, hora: '11:00' },
                { id: id++, data: data, dia: dia, hora: '12:00' },
                { id: id++, data: data, dia: dia, hora: '13:00' },
                { id: id++, data: data, dia: dia, hora: '14:00' },
            );
        }
        else if (diaSemana === 3) {
            agenda.push(
                { id: id++, data: data, dia: dia, hora: '16:00' },
                { id: id++, data: data, dia: dia, hora: '17:00' },
                { id: id++, data: data, dia: dia, hora: '18:00' },
                { id: id++, data: data, dia: dia, hora: '19:00' },
                { id: id++, data: data, dia: dia, hora: '20:00' },
            );
        }
        else if (diaSemana === 6) {
            agenda.push(
                { id: id++, data: data, dia: dia, hora: '09:00' },
                { id: id++, data: data, dia: dia, hora: '10:00' },
                { id: id++, data: data, dia: dia, hora: '11:00' },
                { id: id++, data: data, dia: dia, hora: '12:00' },
            );
        }
    }

  
    for (let i = 1; i < 29; i++) {
        preencheAgenda(somaDia(dataInicial, i * 86400000));
    }
    return agenda;
}

const somaDia = (dt, valor) => {     
    return dt + valor;
}
