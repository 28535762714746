import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box';
import { RegimeVinculoForm } from './regimeVinculoForm';
import { EspecieVinculoForm } from './especieVinculoForm';
import { DataIniVinculoForm } from './dataIniVinculoForm';
import { DataFimVinculoForm } from './dataFimVinculoForm';
import { AtividadeVinculoForm } from './atividadeVinculoForm';
import { TipoVinculoForm } from './tipoVinculoForm';
import { CboVinculoForm } from './cboVinculoForm';
import { LocalVinculoForm } from './localVinculoForm';
import { ObsVinculoForm } from './obsVinculoForm';
import { TabelaVinculo } from './tabelaVinculo';
import Vinculo from '../../elemento/vinculo';
import { tempoEntreDatas, calculaDias, calculaTempoEspecial } from '../../apoio/calculaTempo';


export const VinculoForm = (props) => {

    const [regime, setRegime] = useState(props.vinculo.regime);
    const [especie, setEspecie] = useState(props.vinculo.especie);
    const [dataIni, setDataIni] = useState(props.vinculo.dataIni);
    const [dataFim, setDataFim] = useState(props.vinculo.dataFim);
    const [cbo, setCbo] = useState(props.vinculo.cbo);
    const [atividade, setAtividade] = useState(props.vinculo.atividade);
    const [local, setLocal] = useState(props.vinculo.local);
    const [obs, setObs] = useState(props.vinculo.obs);
    const [tempo, setTempo] = useState(props.vinculo.tempo);
    const [periodo, setPeriodo] = useState(props.vinculo.tempo);
    const [tipo, setTipo] = useState(props.vinculo.tipo);


    useEffect(() => {
        const verificaTempo = () => {
            let total = 0;
            let periodo = '';
            if (dataIni && dataFim) {
                periodo = tempoEntreDatas(dataIni, dataFim, 1);
                total = calculaDias(periodo);
            }
            if (tipo === 'Especial') {
                    periodo = calculaTempoEspecial(periodo, props.sexoContato);
                    total = calculaDias(periodo);
            }
            setTempo(total);
            setPeriodo(periodo);
        }
        verificaTempo();
    }, [dataIni, dataFim, tipo, props.sexoContato]);

    const insereClienteVinculo = () => {
        if (props.vinculo.id) {
            props.altera(new Vinculo(props.vinculo.id, props.idContato, regime, especie, dataIni, dataFim,
                atividade, cbo, local, obs, tempo, periodo, tipo));
        }
        else {
            props.insere(
                new Vinculo(0, props.idContato, regime, especie, dataIni, dataFim,
                    atividade, cbo, local, obs, tempo, periodo, tipo));
            console.log('Vínculo inserido com sucesso!');
        }
    }

    return (

        <Grid item xs={12} >
            <Box marginTop={0} margin={'auto'} >
                {
                    regime ? (
                        especie ? (
                            dataIni ? (
                                dataFim ? (
                                    cbo ? (
                                        atividade ? (
                                            tipo ? (
                                                local ? (
                                                    obs ? (
                                                        <TabelaVinculo
                                                            regime={regime} setRegime={setRegime}
                                                            especie={especie} setEspecie={setEspecie}
                                                            dataIni={dataIni} setDataIni={setDataIni}
                                                            dataFim={dataFim} setDataFim={setDataFim}
                                                            tempo={tempo} setTempo={setDataIni}
                                                            periodo={periodo} setPeriodo={setDataIni}
                                                            atividade={atividade} setAtividade={setAtividade}
                                                            cbo={cbo} setCbo={setCbo}
                                                            tipo={tipo} setTipo={setTipo}
                                                            local={local} setLocal={setLocal}
                                                            obs={obs} setobs={setObs}
                                                            insere={insereClienteVinculo}
                                                            voltar={props.voltar}
                                                        />

                                                    ) : (
                                                            <ObsVinculoForm setValue={setObs} valor={props.vinculo.obs} />
                                                        )
                                                ) : (
                                                        <LocalVinculoForm setValue={setLocal} valor={props.vinculo.local} />
                                                    )
                                            ) : (
                                                    <TipoVinculoForm setValue={setTipo} valor={props.vinculo.tipo} />
                                                )
                                        ) : (
                                                <AtividadeVinculoForm setValue={setAtividade} valor={props.vinculo.atividade} />
                                            )
                                    ) : (
                                            <CboVinculoForm setValue={setCbo} valor={props.vinculo.cbo} />
                                        )
                                ) : (
                                        <DataFimVinculoForm setValue={setDataFim} valor={props.vinculo.dataFim} />
                                    )
                            ) : (
                                    <DataIniVinculoForm setValue={setDataIni} valor={props.vinculo.dataIni} />
                                )
                        ) : (
                                <EspecieVinculoForm setValue={setEspecie} valor={props.vinculo.especie} />
                            )
                    ) : (
                            <RegimeVinculoForm setValue={setRegime} valor={props.vinculo.regime} />
                        )
                }
            </Box>
        </Grid>

    )
}