import { enderecoServidor } from '../apoio/enderecoServidor';
const endereco = enderecoServidor();

const ApiAdvogadoAgenda = {

    ListaAdvogadoAgenda: () => {
        return fetch(endereco + '/advogado-agenda')
            .then(res => res.json());
    },

    ListaViewCliente: () => {
        return fetch(endereco + '/advogado-agenda-vw')
            .then(res => res.json());
    },

    CriaAdvogadoAgenda: dado => {
        return fetch(endereco + '/advogado-agenda', {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 
                'content-type': 'application/json'
                }, 
            body: dado
        })
        .then(res => res.json());
    },

    BuscaAdvogadoAgenda: (id) => {
        return fetch(`${endereco}/advogado-agenda/${id}`)
            .then(res => res.json());
    },

    BuscaAdvogadoAgendaFim: () => {
        return fetch(`${endereco}/advogado-agenda-fim`)
            .then(res => res.json());
    },

    BuscaAdvogadoAgendaCpf: (cpf) => {
        return fetch(`${endereco}/advogado-agenda-cpf/${cpf}`)
            .then(res => res.json());
    },
    
    AlteraAdvogadoAgenda: dado => {
         return fetch(endereco + '/advogado-agenda', {
             method: 'PATCH',
             headers: {
                 'Accept': 'application/json', 
                 'content-type': 'application/json'
                 }, 
             body: dado
         })
         .then(res => res.json());
     },

    RemoveAdvogadoAgenda: id => {
        return fetch(`${endereco}/advogado-agenda/${id}`, {
            method: 'DELETE',
            headers: { 'content-type': 'application/json' },
        })
       .then(res => res.json());
    }

}

export default ApiAdvogadoAgenda;