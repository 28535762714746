import ApiClienteVinculo from '../api/apiClienteVinculo';

export const verificaVinculos = (id, setVinculos) => {
    ApiClienteVinculo.ListaClienteVinculoContato(id)
        .then(result => {
            if (result) {
                setVinculos(result); 
            }
            else {
                alert(`Nenhum vinculo cadastrado!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de autenticação!`);
        }
        );
}