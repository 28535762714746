import ApiLogin from '../api/apiLogin';

export const verificaCpf = (cpf, setaCpf) => {
    ApiLogin.VerificaCpf(cpf)
        .then(result => {
            if (result) {
                setaCpf(result.id);
            }
            else {
                alert(`CPF não cadastrado!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de autenticação!`);
        }
        );
}