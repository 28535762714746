import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonForm } from '../buttonForm';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Frase from '../../objeto/frase';
import { formataData } from '../../apoio/formataData';
import { tempoEntreDatas, calculaDias, verPontos, somaPeriodos } from '../../apoio/calculaTempo';
import { dataDate, comparaData } from '../../apoio/dataDate';

export const ResumeContatoForm = (props) => {

    let carencia = Math.trunc(parseInt(props.contato.tsv.substring(10)) / 30);
    const ordenado = props.vinculos.sort((a, b) => {
        return comparaData(a.dataIni, b.dataIni);
    })

    const Linha = (props) => {

        let data = formataData(props.dataAv);
        let tempoIdade = tempoEntreDatas(props.dataNa, data, 0);
        let idade = tempoIdade + " " + calculaDias(tempoIdade);
        let pontos = verPontos(somaPeriodos(props.tempoSv, tempoIdade));

        return (
            <>
                <Grid item xs={3} >
                    <Frase texto={`${data}`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={4} >
                    <Frase texto={`${props.tempoSv}`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={4} >
                    <Frase texto={`${idade}`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={1} >
                    <Frase texto={`${pontos}`} align={'left'} size={12} />
                </Grid>
            </>
        );
    }


    const condicao = () => {

        let maior = 0;
        let atividade = '';
        let especie = '';
        let ret = '??'

        props.vinculos.forEach(element => {
            if (dataDate(element.dataFim).getTime() > maior) {
                maior = dataDate(element.dataFim).getTime();
                especie = element.especie;
                atividade = element.atividade;
            }
        });

        if (atividade === 'militar' && dataDate(props.data).getTime() < (maior + (86400000 * 90))) ret = 'sim';
        else if (especie === 'Facultativo' && dataDate(props.data).getTime() < (maior + (86400000 * 180))) ret = 'sim';
        else if (dataDate(props.data).getTime() < (maior + (86400000 * 365))) ret = 'sim';

        return ret;
    }


    return (
        <>
            <Box marginTop={1} marginBottom={2} >
                <Paper style={{ padding: 2 }} >
                </Paper>
            </Box>
            <Grid container spacing={1} >

                <Grid item xs={7} >
                    <Frase texto={`${props.contato.nome}`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={5} >
                    <Frase texto={`${props.contato.dn}`} align={'right'} size={12} />
                </Grid>

                <Grid item xs={12} >
                    <Box marginTop={1} marginBottom={2} >
                        <Paper style={{ padding: 2 }} >
                        </Paper>
                    </Box>
                </Grid>

                <Grid container spacing={1}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Box marginTop={1} marginBottom={1} >
                        {
                            ordenado.map((element) => (
                                <Grid item xs={12} key={element.id} >
                                    <Frase texto={`${element.regime} ${element.dataIni} ${element.dataFim} ${(element.tipo === 'Comum') ? ('C') : ('E')} ${element.periodo} `} size={12} />
                                </Grid>
                            ))
                        }
                    </Box>
                </Grid>

                <Grid item xs={12} >
                    <Box marginTop={1} marginBottom={2} >
                        <Paper style={{ padding: 2 }} >
                        </Paper>
                    </Box>
                </Grid>

                <Grid item xs={3} >
                    <Frase texto={`Data`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={4} >
                    <Frase texto={`Tempo`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={4} >
                    <Frase texto={`Idade`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={1} >
                    <Frase texto={`Pto`} align={'left'} size={12} />
                </Grid>

                <Linha tempoSv={props.contato.tsv} dataAv={dataDate(props.data).getTime()} dataNa={props.contato.dn} />

                <Grid item xs={3} >
                    <Frase texto={`Carência`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={4} >
                    <Frase texto={`${carencia}`} align={'left'} size={12} />
                </Grid>

                <Grid item xs={4} >
                    <Frase texto={`Segurado`} align={'left'} size={12} />
                </Grid>
                <Grid item xs={1} >
                    <Frase texto={condicao()} align={'left'} size={12} />
                </Grid>

            </Grid>

            <Grid item xs={12} >
                <Box marginTop={2} marginBottom={2} >
                    <Paper style={{ padding: 2 }} >
                    </Paper>
                </Box>
            </Grid>

            <Grid container spacing={1} >
                <Grid item xs={8} >
                    <ButtonForm label={'Enviar e-mail'} envia={props.setValue} />
                </Grid>
                <Grid item xs={4} >
                    <ButtonForm label={'Sair'} envia={props.setValue} />
                </Grid>
            </Grid>
        </>
    );

}