export const formataData = dado => {
    let dataString = new Date(Number(dado));
    return "" +
        incluiZero(dataString.getDate()) + "-" +
        incluiZero(dataString.getMonth() + 1) + "-"
        + dataString.getFullYear();
}

const incluiZero = (n) => {
    if (n < 10) return '0' + n;
    return n;
}