class Vinculo {
    constructor(id = 0, idContato = 0, regime = '', especie = '', dataIni = '', 
    dataFim = '', atividade = '', cbo = '', local = '', obs = '', tempo = '', periodo = '', tipo = '') {
      this.id = id;
      this.idContato = idContato;
      this.regime = regime;
      this.especie = especie;
      this.dataIni = dataIni;
      this.dataFim = dataFim;
      this.atividade = atividade;
      this.cbo = cbo;      
      this.local = local;
      this.obs = obs;
      this.tempo = tempo;
      this.periodo = periodo;
      this.tipo = tipo;     
    }
  
  }
  export default Vinculo;