import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';
import Tabela from '../../tabela/tabela';
import { formataData } from '../../apoio/formataData';


export const TabelaAgendaForm = (props) => {

    const [selected, setSelected] = useState('');
    const [value, setValue] = useState(false);

    const dados = props.data;

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if (selected && value) {
            props.insere(selected);
        }
        else {
            alert(`Selecione data e autorize contato!`);
        }
    }

    const selecionaDados = id => {

        let agendamento = '';

        dados.forEach(element => {
            if (element.id === id) {
                agendamento = element;
            }
        });

        setSelected({ id: agendamento.id, data: agendamento.data, dia: agendamento.dia, hora: agendamento.hora });
        setValue(true);

    }

    const campos = [
        { titulo: 'Data', dado: 'data' },
        { titulo: 'Dia-semana', dado: 'dia' },
        { titulo: 'Hora', dado: 'hora' }
    ]

    return (
        <Paper style={{ padding: 10 }} >
            <Grid container spacing={1} >

                <Grid item xs={12} >
                    {
                        selected ? (
                            <FraseAlerta texto={"Agendar para " + formataData(selected.data) + " " + selected.dia + " " + selected.hora} size={12} cor={'red'} />
                        ) : (
                                <FraseAlerta texto={'Agende data e hora para receber contato telefônico'} size={12} cor={'red'} />
                            )
                    }
                </Grid>

                <Grid item xs={12} >
                    <Tabela titulo={'AGENDAMENTO'} campos={campos} dados={dados} selecionaDados={selecionaDados} />
                </Grid>

                <Grid item xs={12} >
                    <FormControlLabel
                        control={<Checkbox checked={value} onChange={() => setValue(!value)} name="autoriza_agenda" />}
                        label="Autorizo receber contato por telefone/e-mail"
                    />
                </Grid>

                <Grid item xs={12} >
                    <ButtonForm label={'Confirma agendamento'} envia={handleSubmit} />
                </Grid>

            </Grid>
        </Paper>

    );

}