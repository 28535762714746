import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box';
import { VerificaCep } from '../../acesso/verificaCep';
import { CpfClienteForm } from '../cliente/cpfClienteForm';
import { CepClienteForm } from '../cliente/cepClienteForm';
import { NomeClienteForm } from '../cliente/nomeClienteForm';
import { DataClienteForm } from '../cliente/dataClienteForm';
import { SexoClienteForm } from '../cliente/sexoClienteForm';
import { PaiClienteForm } from '../cliente/paiClienteForm';
import { MaeClienteForm } from '../cliente/maeClienteForm';
import { MunicipioClienteForm } from '../cliente/municipioClienteForm';
import { LogradouroClienteForm } from '../cliente/logradouroClienteForm';
import { NumeroClienteForm } from '../cliente/numeroClienteForm';
import { BairroClienteForm } from '../cliente/bairroClienteForm';
import { EcClienteForm } from '../cliente/estadoClienteForm';
import { EmailClienteForm } from '../cliente/emailClienteForm';
import { TelefoneClienteForm } from '../cliente/telefoneClienteForm';
import { MensagemClienteForm } from '../cliente/mensagemClienteForm';
import { DataResumoClienteForm } from '../cliente/dataResumoClienteForm';
import { TemaClienteForm } from '../cliente/temaClienteForm';
import { RgClienteForm } from '../cliente/rgClienteForm';
import { SenhaClienteForm } from '../cliente/senhaClienteForm';
import { TabelaAtendimento } from '../advogado/tabelaAtendimento';
import { AlteraClienteContato } from '../../crud/crudClienteContato';
import Contato from '../../elemento/contato';
import { limpaNum } from '../../apoio/limpaNum';
import { TabelaExibeVinculos } from '../vinculo/tabelaExibeVinculos';
import { verificaVinculos } from '../../acesso/verificaVinculos';
import { CriaClienteVinculo, AlteraClienteVinculo, RemoveClienteVinculo } from '../../crud/crudClienteVinculo';
import { ResumeContatoForm } from './resumeContatoForm';
import { somaPeriodos } from '../../apoio/calculaTempo';



export const AtendimentoForm = (props) => {

    const [cpf, setCpf] = useState(props.contato.cpf);
    const [cep, setCep] = useState(props.contato.cep);
    const [nome, setNome] = useState(props.contato.nome);
    const [data, setData] = useState(props.contato.dn);
    const [pai, setPai] = useState(props.contato.pai);
    const [mae, setMae] = useState(props.contato.mae);
    const [rg, setRg] = useState(props.contato.rg);
    const [sexo, setSexo] = useState(props.contato.sexo);
    const [ec, setEc] = useState(props.contato.ec);
    const [tsv, setTsv] = useState(props.contato.tsv);
    const [email, setEmail] = useState(props.contato.email);
    const [telefone, setTelefone] = useState(props.contato.telefone);
    const [logradouro, setLogradouro] = useState(props.contato.logradouro);
    const [numero, setNumero] = useState(props.contato.numero);
    const [bairro, setBairro] = useState(props.contato.bairro);
    const [municipio, setMunicipio] = useState(props.contato.municipio);
    const [tema, setTema] = useState(props.contato.tema);
    const [senha, setSenha] = useState(props.contato.senha);
    const [mensagem, setMensagem] = useState(props.contato.mensagem);
    const [dataResumo, setDataResumo] = useState('...');
    const [resumo, setResumo] = useState(false);
    const [vinculos, setVinculos] = useState([]);


    useEffect(() => {
        verificaVinculos(props.contato.id, setVinculos);
    }, [props.contato.id]);

    const ajustaId = () => {
        let ret = 0;
        vinculos.forEach(element => {
            if (element.id > ret) ret = element.id;
        });
        return ret + 1;
    }

    const verifica = (valor) => {
        VerificaCep(valor, setMunicipio);
    }

    const alteraCliente = () => {
        totalTempo();
        const dadosAtualizados = props.contatos.filter(registro => {
            return registro.id !== props.contato.id;
        })

        let contato = new Contato(props.contato.id, limpaNum(cpf), limpaNum(cep),
            nome, data, pai, mae, rg, sexo, ec, tsv, email, limpaNum(telefone),
            logradouro, numero, bairro, municipio, tema, senha, mensagem);

        AlteraClienteContato(contato);
        contato.data = props.contato.data;
        contato.hora = props.contato.hora;
        props.setContatos([contato, ...dadosAtualizados]);

        console.log('cliente alterado com sucesso!');
    }

    const insereClienteVinculo = (vinculo) => {
        vinculo.id = ajustaId();
        CriaClienteVinculo(vinculo);
        setVinculos([vinculo, ...vinculos]);
        console.log('atendimento form cliente inserido com sucesso!');
    }

    const alteraClienteVinculo = (vinculo) => {

        AlteraClienteVinculo(vinculo);

        const dadosAtualizados = vinculos.filter(registro => {
            return registro.id !== vinculo.id;
        });

        setVinculos([vinculo, ...dadosAtualizados]);
        console.log('atendimento form cliente inserido com sucesso!');
    }

    const removeClienteVinculo = id => {

        RemoveClienteVinculo(id);
        const dadosAtualizados = vinculos.filter(registro => {
            return registro.id !== id;
        })
        setVinculos([...dadosAtualizados]);
    }


    const totalTempo = () => {
        let totD = vinculos.reduce((prevValue, elem) => prevValue + parseInt(elem.tempo), 0);
        let totP = vinculos.reduce((prevValue, elem) => somaPeriodos(prevValue, elem.periodo), '00A00M00D');
        if (totD > 0) setTsv(totP + " " + totD);
        else setTsv('...')
    }

    return (


        <Grid item xs={12} >

            <Box marginTop={0} margin={'auto'} >

                {
                    cpf ? (
                        cep ? (
                            nome ? (
                                data ? (
                                    pai ? (
                                        mae ? (
                                            rg ? (
                                                sexo ? (
                                                    ec ? (
                                                        tsv ? (
                                                            email ? (
                                                                telefone ? (
                                                                    logradouro ? (
                                                                        numero ? (
                                                                            bairro ? (
                                                                                municipio ? (
                                                                                    tema ? (
                                                                                        senha ? (
                                                                                            mensagem ? (
                                                                                                dataResumo ? (
                                                                                                    resumo ? (
                                                                                                        <ResumeContatoForm
                                                                                                            contato={props.contato}
                                                                                                            vinculos={vinculos}
                                                                                                            data={dataResumo}
                                                                                                            setValue={() => setResumo(false)} />

                                                                                                    ) : (
                                                                                                            <TabelaAtendimento
                                                                                                                cpf={cpf} setCpf={setCpf}
                                                                                                                cep={cep} setCep={setCep}
                                                                                                                nome={nome} setNome={setNome}
                                                                                                                data={data} setData={setData}
                                                                                                                pai={pai} setPai={setPai}
                                                                                                                mae={mae} setMae={setMae}
                                                                                                                rg={rg} setRg={setRg}
                                                                                                                sexo={sexo} setSexo={setSexo}
                                                                                                                ec={ec} setEc={setEc}
                                                                                                                tsv={tsv} setTsv={setTsv}
                                                                                                                email={email} setEmail={setEmail}
                                                                                                                telefone={telefone} setTelefone={setTelefone}
                                                                                                                logradouro={logradouro} setLogradouro={setLogradouro}
                                                                                                                numero={numero} setNumero={setNumero}
                                                                                                                bairro={bairro} setBairro={setBairro}
                                                                                                                municipio={municipio} setMunicipio={setMunicipio}
                                                                                                                tema={tema} setTema={setTema}
                                                                                                                senha={senha} setSenha={setSenha}
                                                                                                                mensagem={mensagem} setMensagem={setMensagem}
                                                                                                                dataResumo={dataResumo} setDataResumo={setDataResumo}
                                                                                                                insere={alteraCliente}
                                                                                                                voltar={props.voltar}
                                                                                                            />
                                                                                                        )

                                                                                                ) : (
                                                                                                        <DataResumoClienteForm setValue={setDataResumo} setResumo={setResumo} valor={''} />
                                                                                                    )
                                                                                            ) : (
                                                                                                    <MensagemClienteForm setValue={setMensagem} valor={props.contato.mensagem} />
                                                                                                )

                                                                                        ) : (
                                                                                                <SenhaClienteForm setValue={setSenha} valor={props.contato.senha} />
                                                                                            )
                                                                                    ) : (
                                                                                            <TemaClienteForm setValue={setTema} valor={props.contato.tema} />
                                                                                        )
                                                                                ) : (
                                                                                        <MunicipioClienteForm setValue={setMunicipio} valor={props.contato.municipio} />
                                                                                    )
                                                                            ) : (
                                                                                    <BairroClienteForm setValue={setBairro} valor={props.contato.bairro} />
                                                                                )
                                                                        ) : (
                                                                                <NumeroClienteForm setValue={setNumero} valor={props.contato.numero} />
                                                                            )
                                                                    ) : (
                                                                            <LogradouroClienteForm setValue={setLogradouro} valor={props.contato.logradouro} />
                                                                        )
                                                                ) : (
                                                                        <TelefoneClienteForm setValue={setTelefone} valor={props.contato.telefone} />
                                                                    )
                                                            ) : (
                                                                    <EmailClienteForm setValue={setEmail} valor={props.contato.email} />
                                                                )
                                                        ) : (
                                                                <TabelaExibeVinculos setValue={totalTempo}
                                                                    contatoId={props.contato.id}
                                                                    contatoSexo={props.contato.sexo}
                                                                    vinculos={vinculos}
                                                                    setVinculos={setVinculos}
                                                                    valor={props.contato.tsv}
                                                                    insereVinculo={insereClienteVinculo}
                                                                    alteraVinculo={alteraClienteVinculo}
                                                                    removeVinculo={removeClienteVinculo} />
                                                            )
                                                    ) : (
                                                            <EcClienteForm setValue={setEc} valor={props.contato.ec} />
                                                        )
                                                ) : (
                                                        <SexoClienteForm setValue={setSexo} valor={props.contato.sexo} />
                                                    )
                                            ) : (
                                                    <RgClienteForm setValue={setRg} valor={props.contato.rg} />
                                                )
                                        ) : (
                                                <MaeClienteForm setValue={setMae} valor={props.contato.mae} />
                                            )
                                    ) : (
                                            <PaiClienteForm setValue={setPai} valor={props.contato.pai} />
                                        )
                                ) : (
                                        <DataClienteForm setValue={setData} valor={props.contato.dn} />
                                    )
                            ) : (
                                    <NomeClienteForm setValue={setNome} valor={props.contato.nome} />
                                )
                        ) : (
                                <CepClienteForm setValue={setCep} verifica={verifica} valor={props.contato.cep} />
                            )
                    ) : (
                            <CpfClienteForm setValue={setCpf} valor={props.contato.cpf} />
                        )
                }
            </Box>
        </Grid>

    )
}
