import { enderecoServidor } from '../apoio/enderecoServidor';
const endereco = enderecoServidor();

const ApiClienteContato = {

    ListaClienteContato: () => {
        return fetch(endereco + '/cliente-contato')
            .then(res => res.json());
    },

    ListaViewContato: () => {
        return fetch(endereco + '/cliente-contato-vw')
            .then(res => res.json());
    },

    CriaClienteContato: dado => {
        return fetch(endereco + '/cliente-contato', {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 
                'content-type': 'application/json'
                }, 
            body: dado
        })
        .then(res => res.json());
    },

    BuscaClienteContato: (id) => {
        return fetch(`${endereco}/cliente-contato/${id}`)
            .then(res => res.json());
    },

    BuscaClienteContatoCpf: (cpf) => {
        return fetch(`${endereco}/cliente-contato-cpf/${cpf}`)
            .then(res => res.json());
    },
    
    AlteraClienteContato: dado => {
         return fetch(endereco + '/cliente-contato', {
             method: 'PATCH',
             headers: {
                 'Accept': 'application/json', 
                 'content-type': 'application/json'
                 }, 
             body: dado
         })
         .then(res => res.json());
     },

    RemoveClienteContato: id => {
        return fetch(`${endereco}/cliente-contato/${id}`, {
            method: 'DELETE',
            headers: { 'content-type': 'application/json' },
        })
       .then(res => res.json());
    }

}

export default ApiClienteContato;