import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { TextoForm } from '../textoForm';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';


export const MensagemClienteForm = (props) => {

    const [value, setValue] = useState(props.valor ? (props.valor): (''));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (value.length > 50 && value.length < 500) {
            props.setValue(value);
        }
        else {
            alert(`Mensagem digitada é inválida!`);
        }
        setValue('');
    }


    return (
        <form>
            <Paper style={{ padding: 10 }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Digite sua mensagem'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <TextoForm label='Mensagem' nome='mensagem' type='text' focus={true} length={500} min={8} max={14} value={value} setValue={setValue} />
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Confirma'} envia={handleSubmit} />
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}

