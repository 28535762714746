import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { dataMask } from '../../apoio/dataMask';
import { validaData } from '../../validacao/validaData';
import { StringForm } from '../stringForm';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';


export const DataResumoClienteForm = (props) => {

    const [value, setValue] = useState(props.valor ? (props.valor): (''));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (validaData(value)) {
            props.setValue(value);
            props.setResumo(true);
        }
        else {
            alert(`Data digitada é inválida!`);
        }
        setValue('');
    }

    return (
        <form>
            <Paper style={{ padding: 10 }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Digite data final do resumo'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <StringForm label='Data Resumo' nome='dataresumo' type='tel' focus={true} value={dataMask(value)} setValue={setValue} />
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Confirma'} envia={handleSubmit} />
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}