import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { validaSenha } from '../validacao/validaSenha';
import { verificaSenha } from '../acesso/verificaSenha';
import { StringForm } from './stringForm';

export const SenhaForm = (props) => {

  const [senha, setSenha ] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (validaSenha(senha)) {
      verificaSenha(props.user, senha, props.setaLogado);
    }
    else {
      alert(`Senha digitada é inválida!`);
    }
    setSenha('');
  }
  
  return (
    <form>
      <Grid container spacing={1} >
        <Grid item xs={12} >
          <StringForm label='Senha' nome='senha' type='password' focus={true} value={senha} setValue={setSenha} />
        </Grid>
        <Grid item xs={12} >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Enviar
              </Button>
        </Grid>
      </Grid>
    </form>
  );
}

