import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Titulo from './objeto/titulo';
import Foto from './objeto/foto';
import Frase from './objeto/frase';
import Botao from './objeto/botao';
import { Login } from './objeto/login';
//import { NameForm } from './form/formTeste';
//import { SenhaForm } from './form/senhaForm';
//import { CpfForm } from './form/cpfForm';
//import { icone } from './imagem/icone.svg';
//import { foto } from './imagem/foto2.jpg';
//import { Alerta } from './apoio/exibeAlerta';
import { ContatoForm } from './form/contatoForm';
import { LogadoForm } from './form/logadoForm';
//import { ListaAdvogadoAgenda } from './crud/crudAdvogadoAgenda';
//import { verificaAgenda } from './acesso/verificaAgenda';
//import { verificaContatos } from './acesso/verificaContatos';



const App = () => {

  const [login, setLogin] = useState(false);
  const [logado, setLogado] = useState(false);
  const [contato, setContato] = useState(false);
  //const [data, setData] = useState('');
 // const [contatos, setContatos] = useState('');
/*
  useEffect(() => {
    verificaAgenda(setData);
    verificaContatos(setContatos);
},[]);*/

  return (

    <Container component="main" maxWidth="lg" >
      <CssBaseline />
      <Grid container spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        style={{
          color: 'white',
          backgroundColor: '#303f9f',
          borderRadius: 10,
          marginTop: 5,
          //marginBottom: 20,
        }}
      >
        <Titulo texto='ESCRITÓRIO VIRTUAL' top={0} />

      </Grid>

      {
        contato ? (
          <ContatoForm retorna={() => setContato(false)} />
        ) : (
            logado ? (
              <LogadoForm retorna={() => {setLogado(false); setLogin(false)}} />
              
            ) : (
                <>
                  <Grid container spacing={1}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Frase texto='Bem vindo ao meu escritório virtual!' size={18} top={30} />
                    <Grid item xs={12} >
                      {
                        login ? (
                          <Login setaLogin={() => setLogin(false)} setaLogado={() => setLogado(true)} />
                        ) : (
                            <Foto w={220} h={220} top={20} setaLogin={setLogin} />
                          )

                      }
                    </Grid>

                    <Grid item xs={12} >
                      <Frase texto='OAB - RS 105466' top={20} />
                      <Frase texto='Antonio Jair da Silva' />
                      <Frase texto='Santa Maria - RS' />
                    </Grid>

                  </Grid>

                  <Grid container spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ marginTop: 30 }}
                  >
                    <Grid item xs={12} md={8} >
                      <Botao texto='Entrar em contato' top={0} cor='#76ff03' setaValue={setContato} />
                    </Grid>
                    <Grid item xs={12} md={12} >
                      <Frase texto='Direito Previdenciário - Estado do Rio Grande do Sul, Brasil' size={10} top={10} />
                    </Grid>

                  </Grid>
                </>
              )
          )
      }

    </Container>

  );
}
export default App;



