import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import UndoIcon from '@material-ui/icons/Undo';
import Frase from '../objeto/frase';
import { VerificaCep } from '../acesso/verificaCep';
import { CpfClienteForm } from './cliente/cpfClienteForm';
import { CepClienteForm } from './cliente/cepClienteForm';
import { NomeClienteForm } from './cliente/nomeClienteForm';
import { DataClienteForm } from './cliente/dataClienteForm';
import { SexoClienteForm } from './cliente/sexoClienteForm';
import { EmailClienteForm } from './cliente/emailClienteForm';
import { TelefoneClienteForm } from './cliente/telefoneClienteForm';
import { MensagemClienteForm } from './cliente/mensagemClienteForm';
import { AutorizaClienteForm } from './cliente/autorizaClienteForm';
import { TemaClienteForm } from './cliente/temaClienteForm';
import { TabelaClienteForm } from './cliente/tabelaClienteForm';
import { CriaClienteContato } from '../crud/crudClienteContato';
import { ListaAdvogadoAgenda } from '../crud/crudAdvogadoAgenda';
import { CriaAdvogadoAgendamento } from '../crud/crudAdvogadoAgendamento';
import Contato from '../elemento/contato';
import Agendamento from '../elemento/agendamento';
import { limpaNum } from '../apoio/limpaNum';
import { TabelaAgendaForm } from './cliente/tabelaAgendaForm';
import { formataData } from '../apoio/formataData';



export const ContatoForm = (props) => {

    const [autoriza, setAutoriza] = useState(false);
    const [cpf, setCpf] = useState('');
    const [cep, setCep] = useState('');
    const [nome, setNome] = useState('');
    const [data, setData] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [bairro, setBairro] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [sexo, setSexo] = useState('');
    const [tema, setTema] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [confirma, setConfirma] = useState('');
    const [agendamento, setAgendamento] = useState(false);
    const [agendado, setAgendado] = useState('');
    const [disponivel, setDisponivel] = useState('');

    
    const setIdContato = (id) => {
        setConfirma(id);
    }

    const setIdAgendamento = (id) => {
        if (id > 0) setAgendamento(true);
    }

    const buscaAgenda = () => {
        ListaAdvogadoAgenda(setDisponivel);
    }

    const verifica = (valor) => {
        VerificaCep(valor, setLogradouro, setBairro, setMunicipio);
    }

    const insereCliente = () => {
        buscaAgenda();

        CriaClienteContato(
            new Contato(0, limpaNum(cpf), limpaNum(cep),
                nome, data, '...', '...', '...', sexo, '...', '...', email, limpaNum(telefone),
                logradouro, '...', bairro, municipio, tema, '...', mensagem), setIdContato);
        console.log('cliente inserido com sucesso!');
    }

    const insereAgendamento = (agendamento) => {

        setAgendado(agendamento);

        CriaAdvogadoAgendamento(
            new Agendamento(0, confirma, agendamento.id, 'novo'), setIdAgendamento);
        console.log('agendamento inserido com sucesso!');
    }


    return (

        <Container component="main" maxWidth="sm" >
            <Grid container spacing={1}
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} >
                    <Frase texto='ATENDIMENTO NÃO PRESENCIAL' top={20} />
                    <Frase texto='OAB - RS 105466' />
                </Grid>
            </Grid>


            <Box marginTop={3} >

                {autoriza ? (
                    cpf ? (
                        cep ? (
                            nome ? (
                                data ? (
                                    email ? (
                                        telefone ? (
                                            sexo ? (
                                                tema ? (
                                                    mensagem ? (
                                                        confirma ? (
                                                            agendamento ? (
                                                                <>
                                                                    <Box marginTop={3} >
                                                                        <Paper style={{ padding: 5 }} >
                                                                        </Paper>
                                                                    </Box>

                                                                    <Frase
                                                                        texto={`Sr(a) ${nome}`}
                                                                        top={15} 
                                                                    />
                                                                    <Frase
                                                                        texto={`Contato agendado com sucesso!`}
                                                                        top={10} 
                                                                    />
                                                                    <Frase
                                                                        texto={`${agendado.dia}, ${formataData(agendado.data)} às ${agendado.hora} h`}
                                                                        top={10} 
                                                                    />
                                                                    <Frase
                                                                        texto={'Na data e hora agendados reúna os documentos pertinentes ao seu caso e aguarde contato telefônico do advogado.'}
                                                                        top={10} 
                                                                    />
                                                                    <Box marginTop={3} >
                                                                        <Paper style={{ padding: 5 }} >
                                                                        </Paper>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                    <TabelaAgendaForm
                                                                        //setValue={() => console.log('agenda')}
                                                                        data={disponivel}
                                                                        insere={insereAgendamento}
                                                                    />
                                                                )
                                                        ) : (
                                                                <TabelaClienteForm
                                                                    cpf={cpf} setCpf={setCpf}
                                                                    cep={cep} setCep={setCep}
                                                                    nome={nome} setNome={setNome}
                                                                    data={data} setData={setData}
                                                                    email={email} setEmail={setEmail}
                                                                    telefone={telefone} setTelefone={setTelefone}
                                                                    municipio={municipio} setMunicipio={setCep}
                                                                    sexo={sexo} setSexo={setSexo}
                                                                    tema={tema} setTema={setTema}
                                                                    mensagem={mensagem} setMensagem={setMensagem}
                                                                    insere={insereCliente}
                                                                />
                                                            )
                                                    ) : (
                                                            <MensagemClienteForm setValue={setMensagem} valor={''} />
                                                        )
                                                ) : (
                                                        <TemaClienteForm setValue={setTema} valor={''} />
                                                    )
                                            ) : (
                                                    <SexoClienteForm setValue={setSexo} valor={''} />
                                                )
                                        ) : (
                                                <TelefoneClienteForm setValue={setTelefone} valor={''} />
                                            )
                                    ) : (
                                            <EmailClienteForm setValue={setEmail} valor={''} />
                                        )
                                ) : (
                                        <DataClienteForm setValue={setData} valor={''} />
                                    )
                            ) : (
                                    <NomeClienteForm setValue={setNome} valor={''} />
                                )
                        ) : (
                                <CepClienteForm setValue={setCep} verifica={verifica} valor={''} />
                            )
                    ) : (
                            <CpfClienteForm setValue={setCpf} valor={''} />
                        )
                ) : (
                        <AutorizaClienteForm setValue={setAutoriza} />
                    )
                }

            </Box>

            
            <Grid container spacing={1} alignItems='center' >

                <Grid item xs={12}
                    container
                    align="center"
                    justify="center"
                >
                    <Button
                        size="small"
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 20 }}
                        onClick={props.retorna}
                    >
                        <UndoIcon style={{ color: 'green', fontSize: 30 }} />
                        Página inicial
                        </Button>
                </Grid>              

            </Grid>

        </Container>
    )
}


