import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import UndoIcon from '@material-ui/icons/Undo';
import Frase from '../objeto/frase';
import Botao from '../objeto/botao';
import { TabelaCriaAgenda } from './advogado/tabelaCriaAgenda';
import { TabelaExibeContatos } from './advogado/tabelaExibeContatos';
import { CriaAdvogadoAgenda } from '../crud/crudAdvogadoAgenda';
import Agenda from '../elemento/agenda';
import { verificaAgenda } from '../acesso/verificaAgenda';
import { verificaContatos } from '../acesso/verificaContatos';



export const LogadoForm = (props) => {

    const [agenda, setAgenda] = useState(false);
    const [responde, setResponde] = useState(false);
    const [data, setData] = useState('');
    const [contatos, setContatos] = useState('');

useEffect(() => {
    verificaAgenda(setData);
    verificaContatos(setContatos);
},[]);


    let idAgenda = 0;

    const setId = (id) => {
        idAgenda = id;
    }

    const insereAgenda = (novaAgenda) => {
        novaAgenda.forEach(element => {
            CriaAdvogadoAgenda(new Agenda(0, element.data, element.dia, element.hora), setId);
        }
        );
        if (idAgenda > 0) alert(`Agenda registrada com sucesso!`);
        console.log('agenda inserida com sucesso!');
    }

    const PgBotao = () => {
        return (
            <>
                <Botao texto='Registrar agenda' top={10} cor='#76ff03' setaValue={setAgenda} />
                <Botao texto='Responder contatos' top={10} cor='#76ff03' setaValue={setResponde} />
            </>
        );
    }

    return (

        <Container component="main" maxWidth="sm" >

            <Grid container spacing={1}
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} >
                    <Frase texto='MÓDULO DE ATENDIMENTO' top={20} />
                    <Frase texto='OAB - RS 105466' />
                </Grid>
                <Grid item xs={12}  >
                    {
                        agenda ? (
                            <TabelaCriaAgenda setValue={() => setAgenda(false)} data={Number(data)} insere={insereAgenda} />
                        ) : (
                            responde ? (
                                <TabelaExibeContatos setValue={() => setResponde(false)} contatos={contatos} setContatos = {setContatos} />
                            ) : (
                                    <PgBotao />
                                )
                            )
                    }                    

                </Grid>
                
            </Grid>

            <Box marginTop={3} >
                <Paper style={{ padding: 5 }} >                    
                </Paper>
            </Box>



            <Grid container spacing={1} alignItems='center' >

                <Grid item xs={12}
                    container
                    align="center"
                    justify="center" >
                    <Button
                        size="small"
                        variant='outlined'
                        color='primary'
                        style={{ marginTop: 20 }}
                        onClick={props.retorna}
                    >
                        <UndoIcon style={{ color: 'green', fontSize: 30 }} />
                        Página inicial
                        </Button>
                </Grid>              

            </Grid>

        </Container>
    )

}