import ApiClienteContato from '../api/apiClienteContato';

export const verificaContatos = (setContatos) => {
    ApiClienteContato.ListaViewContato()
        .then(result => {
            if (result) {
                setContatos(result); 
            }
            else {
                alert(`Nenhum contato cadastrado!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de autenticação!`);
        }
        );
}