
export const somaPeriodos = (tempoA, tempoB) => {
    return inteiroParaTempo(tempoParaInteiro(tempoA) + tempoParaInteiro(tempoB));

}

export const tempoParaInteiro = (tempo) => {

    let ano = tempo.substring(0, 2);
    let mes = tempo.substring(3, 5);
    let dia = tempo.substring(6, 8);

    let diaParaMes = parseInt(dia) / 30;
    let mesParaAno = (parseInt(mes) + diaParaMes) / 12;
    let anoParaInteiro = parseInt(ano) + mesParaAno;

    return anoParaInteiro;
}

export const inteiroParaTempo = (tempo) => {

    let ano = Math.trunc(tempo);
    let mesAno = (tempo - ano) * 12;
    let mes = Math.trunc(mesAno);
    let diaAno = (mesAno - mes) * 30;
    let dia = Math.round(diaAno);

    return apresentaTempo(ano, mes, dia);
}

export const apresentaTempo = (ano, mes, dia) => {

    let printAno = '';
    if (ano < 10) printAno = `0${ano}`;
    else printAno = `${ano}`;
    let printMes = '';
    if (mes < 10) printMes = `0${mes}`;
    else printMes = `${mes}`;
    let printDia = '';
    if (dia < 10) printDia = `0${dia}`;
    else printDia = `${dia}`;

    return `${printAno}A${printMes}M${printDia}D`;

}

export const tempoEntreDatas = (dataI, dataF, tipo) => {

    let anoI = parseInt(dataI.substring(6));
    let mesI = parseInt(dataI.substring(3, 5));
    let diaI = parseInt(dataI.substring(0, 2));

    let anoF = parseInt(dataF.substring(6));
    let mesF = parseInt(dataF.substring(3, 5));
    let diaF = parseInt(dataF.substring(0, 2));

    if (diaI > 30) diaI = 30;
    if (diaF > 30) diaF = 30;

    let anos = anoF - anoI;
    let meses = mesF - mesI;
    let dias = diaF - diaI + tipo;

    if (meses < 0) { meses = meses + 12; anos = anos - 1; }
    if (dias < 0) { dias = dias + 30; meses = meses - 1 }
    if (dias > 29) {
        dias = dias % 30;
        meses = meses + 1
    }
    if (meses > 11) { meses = meses - 12; anos = anos + 1; }

    return apresentaTempo(anos, meses, dias);
}

export const calculaDias = (tempo) => {
    let ano = tempo.substring(0, 2);
    let mes = tempo.substring(3, 5);
    let dia = tempo.substring(6, 8);

    return parseInt(ano) * 365 + parseInt(mes) * 30 + parseInt(dia);
}

export const calculaTempoEspecial = (tempo, sexo) => {

    let tempoComAcrescimo = tempoParaInteiro(tempo);

    if (sexo === 'Masculino') {
        tempoComAcrescimo = tempoComAcrescimo * 1.4;
    }
    else {
        tempoComAcrescimo = tempoComAcrescimo * 1.2;
    }

    return inteiroParaTempo(tempoComAcrescimo);

}

export const verPontos = (tempo) => {

    let pontuacao = tempoParaInteiro(tempo);

    return "" + pontuacao.toFixed(2);
}