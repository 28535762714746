export const validaData = data => {

    if (!(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(data))) return false;
    else {

        let dia = data.substring(0, 2);
        let mes = data.substring(3, 5);
        let ano = data.substring(6);

        
        if (parseInt(dia) === 0 || parseInt(dia) > dias(parseInt(mes))) return false;
        else if (parseInt(mes) === 0 || parseInt(mes) > 12) return false;
        else if (parseInt(ano) < 1940 || parseInt(ano) > parseInt(anoAtual())) return false;
        else if(parseInt(ano) === parseInt(anoAtual())){
            if(parseInt(mes) > parseInt(mesAtual())) return false;
            else if(parseInt(mes) === parseInt(mesAtual())){
                if(parseInt(dia) > parseInt(diaAtual())) return false;
            }
        }
        

        return true;

    }

}

const dias = (mes) => {
    let n = 31;
    if (mes === 2) n = 29;
    else if (mes === 4 || mes === 6 || mes === 9 || mes === 11) n = 30;
    return n;
}

const anoAtual = () => {
    return new Date().getFullYear();
}

const mesAtual = () => {
    return new Date().getMonth() + 1;
}

const diaAtual = () => {
    return new Date().getDate();
}