import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { StringForm } from '../stringForm';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';


export const NumeroClienteForm = (props) => {

    const [value, setValue] = useState(props.valor ? (props.valor): (''));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (value.length > 1 && value.length < 20) {
            props.setValue(value);
        }
        else {
            alert(`Nome digitado é inválido!`);
        }
        setValue('');
    }

    return (
        <form>
            <Paper style={{ padding: 10 }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Digite número da residência'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <StringForm label='Numero da residência' nome='numeroresidencia' type='text' focus={true} value={value} setValue={setValue} />
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Confirma'} envia={handleSubmit} />
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}