import React from 'react';
import { Typography } from '@material-ui/core';

const Titulo = (props) => {
    return <Typography
    variant='h6' 
    align='center'
        style={{ fontSize: props.size, marginTop: props.top, color: 'white', padding: 10}}        
    >
    {props.texto}
    </Typography>
}

export default Titulo;