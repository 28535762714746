import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';


export const AutorizaClienteForm = (props) => {

    const [value, setValue] = useState(false);

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (value) {
            props.setValue(value);
        }
        else {
            alert(`Marque a caixa de autorização!`);
        }
        setValue(false);
    }


    return (
        <form>
            <Paper style={{ padding: 10 }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Autorize o registro de seus dados'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <FormControlLabel
                            control={<Checkbox checked={value} onChange={() => setValue(!value)} name="autorizacao" />}
                            label="Autorizo o registro de meus dados pelo sistema"
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Confirma'} envia={handleSubmit} />
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}
