import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';
import Tabela from '../../tabela/tabela';

export const TabelaClienteForm = (props) => {

    const valida = () => {
        let ret = true;
        dados.forEach(element => {
            if (element.registro === '') ret = false;
        });
        return ret;
    }


    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (valida()) {
            props.insere();
        }
        else {
            alert(`Todos os itens devem ser informados!`);
        }
    }


    const exibeDados = id => {

        if (id === 'CPF') props.setCpf('');
        else if (id === 'CEP') props.setCep('');
        else if (id === 'Nome') props.setNome('');
        else if (id === 'Data Nasc') props.setData('');
        else if (id === 'Sexo') props.setSexo('');
        else if (id === 'E-mail') props.setEmail('');
        else if (id === 'Telefone') props.setTelefone('');
        else if (id === 'Município') props.setMunicipio('');
        else if (id === 'Tema') props.setTema('');
        else if (id === 'Mensagem') props.setMensagem('');

    }

    const dados = [
        { id: 'CPF', registro: props.cpf },
        { id: 'CEP', registro: props.cep },
        { id: 'Nome', registro: props.nome },
        { id: 'Data Nasc', registro: props.data },
        { id: 'Sexo', registro: props.sexo },
        { id: 'E-mail', registro: props.email },
        { id: 'Telefone', registro: props.telefone },
        { id: 'Município', registro: props.municipio },
        { id: 'Tema', registro: props.tema },
        { id: 'Mensagem', registro: props.mensagem }
    ]


    const campos = [
        { titulo: 'Item', dado: 'id' },
        { titulo: 'Registro', dado: 'registro' }
    ]

    return (
        <Paper style={{ padding: 10 }} >
            <Grid container spacing={1} >
                <Grid item xs={12} >
                    <FraseAlerta texto={'Confira e confirme seus registros'} size={12} cor={'red'} />
                </Grid>

                <Grid item xs={12} >
                    <Tabela titulo={'CONTATO'} campos={campos} dados={dados} exibeDados={exibeDados} />
                </Grid>
                <Grid item xs={12} >
                    <ButtonForm label={'Confirma registros'} envia={handleSubmit} />
                </Grid>

            </Grid>
        </Paper>

    );
}