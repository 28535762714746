import React from 'react';
import { Typography } from '@material-ui/core';

const FraseAlerta = (props) => {
    return <Typography
    variant= {props.variant} 
    align='center'
    style={{ fontSize: props.size, marginTop: props.top, marginBottom: props.bot, color: props.cor }}        
    >
    <i>{props.texto}</i>
    </Typography>
}
export default FraseAlerta;
