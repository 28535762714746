import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { textoMask } from '../../apoio/textoMask';
import { StringForm } from '../stringForm';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';


export const LogradouroClienteForm = (props) => {

    const [value, setValue] = useState(props.valor ? (props.valor): (''));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (value.length > 6 && value.length < 80) {
            props.setValue(value);
        }
        else {
            alert(`Nome digitado é inválido!`);
        }
        setValue('');
    }

    return (
        <form>
            <Paper style={{ padding: 10 }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Digite logradouro'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <StringForm label='Logradouro' nome='logradouro' type='text' focus={true} value={textoMask(value)} setValue={setValue} />
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Confirma'} envia={handleSubmit} />
                    </Grid>
                </Grid>
            </Paper>
        </form>
    );
}