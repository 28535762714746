import React from "react";
import Button from '@material-ui/core/Button';

export const ButtonForm = (props) => {
    return(
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={props.envia}
          >
            {props.label}
        </Button>
    )
}