import ApiApoio from '../api/apiApoio';
import { limpaNum } from '../apoio/limpaNum';

export const VerificaCep = (cep, setLogradouro, setBairro, setMunicipio) => {

  if (cep.length > 8) {

    ApiApoio.VerificaCep(cep)
      .then(res => {
        if (!res.erro) {
          if (limpaNum(res.cep) === limpaNum(cep)) {
            setLogradouro(res.logradouro);
            setBairro(res.bairro);
            setMunicipio(res.localidade + " - " + res.uf);            
          }
        }
        else {
          setMunicipio('');
          alert(`CEP não cadastrado na base de dados!`);
        }
      })
      .catch(err => {
        console.log(err);
        alert(`Erro de acesso ao servidor de dados do CEP!`);
      }
      );
  }
}