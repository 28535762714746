import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonForm } from '../buttonForm';
import Tabela from '../../tabela/tabela';

export const TabelaAtendimento = (props) => {

    const valida = () => {
        let ret = true;
        dados.forEach(element => {
            if (element.registro === '') ret = false;
        });
        return ret;
    }


    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (valida()) {
            props.insere();
            props.voltar();
        }
        else {
            alert(`Todos os itens devem ser informados!`);
        }
    }


    const exibeDados = id => {

        if (id === 'CPF') props.setCpf('');
        else if (id === 'CEP') props.setCep('');
        else if (id === 'Nome') props.setNome('');
        else if (id === 'Data Nasc') props.setData('');
        else if (id === 'Nome Pai') props.setPai('');
        else if (id === 'Nome Mãe') props.setMae('');
        else if (id === 'RG') props.setRg('');
        else if (id === 'Sexo') props.setSexo('');
        else if (id === 'Est. Civil') props.setEc('');
        else if (id === 'Tempo') props.setTsv('');
        else if (id === 'E-mail') props.setEmail('');
        else if (id === 'Telefone') props.setTelefone('');
        else if (id === 'Logradouro') props.setLogradouro('');
        else if (id === 'Número') props.setNumero('');
        else if (id === 'Bairro') props.setBairro('');
        else if (id === 'Município') props.setMunicipio('');        
        else if (id === 'Tema') props.setTema('');
        else if (id === 'Senha') props.setSenha('');
        else if (id === 'Mensagem') props.setMensagem('');
        else if (id === 'Resumo') props.setDataResumo('');

    }

    const dados = [
        { id: 'CPF', registro: props.cpf },
        { id: 'CEP', registro: props.cep },
        { id: 'Nome', registro: props.nome },
        { id: 'Data Nasc', registro: props.data },
        { id: 'Nome Pai', registro: props.pai },
        { id: 'Nome Mãe', registro: props.mae },
        { id: 'RG', registro: props.rg },
        { id: 'Sexo', registro: props.sexo },
        { id: 'Est. Civil', registro: props.ec },
        { id: 'Tempo', registro: props.tsv },
        { id: 'E-mail', registro: props.email },
        { id: 'Telefone', registro: props.telefone },
        { id: 'Logradouro', registro: props.logradouro },
        { id: 'Número', registro: props.numero },
        { id: 'Bairro', registro: props.bairro },
        { id: 'Município', registro: props.municipio },        
        { id: 'Tema', registro: props.tema },
        { id: 'Senha', registro: props.senha },
        { id: 'Mensagem', registro: props.mensagem },
        { id: 'Resumo', registro: props.dataResumo },
    ]


    const campos = [
        { titulo: 'Item', dado: 'id' },
        { titulo: 'Registro', dado: 'registro' }
    ]

    return (

        <Grid container spacing={1} >
            <Grid item xs={12} >
                <Tabela titulo={'CONTATO'} campos={campos} dados={dados} exibeDados={exibeDados} />
            </Grid>
            <Grid item xs={8} >
                <ButtonForm label={'Atualiza contato'} envia={handleSubmit} />
            </Grid>
            <Grid item xs={4} >
                <ButtonForm label={'Sair'} envia={props.voltar} />
            </Grid>

        </Grid>
    );

}