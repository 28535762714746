import { enderecoServidor } from '../apoio/enderecoServidor';
const endereco = enderecoServidor();

const ApiLogin = {    

    VerificaCpf: (cpf) => {
        return fetch(`${endereco}/advogado-pessoa-cpf/${cpf}`)
            .then(res => res.json());
    },

    VerificaSenha: (id, senha) => {
        return fetch(endereco + `/usuarios/busca-senha/${id}/${senha}`)
        .then(res => res.json());
    },

    CriaAcesso: user => {
         return fetch(endereco + `/acesso/${user}`, {
             method: 'POST',
             headers: {
                 'Accept': 'application/json', 
                 'content-type': 'application/json'
                 },              
         })
         .then(res => res.json());
     },
     
     AlteraAcesso: acesso => {
         return fetch(endereco + `/acesso/${acesso}`, {
             method: 'PATCH',
             headers: {
                 'Accept': 'application/json', 
                 'content-type': 'application/json'
                 },             
         })
         .then(res => res.json());
     },   

}
export default ApiLogin;