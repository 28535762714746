import ApiAdvogadoAgendamento from '../api/apiAdvogadoAgendamento';

export const CriaAdvogadoAgendamento = (dado, setId) => {

    ApiAdvogadoAgendamento.CriaAdvogadoAgendamento(JSON.stringify(dado))
        .then(res => {
            if (res.insertId > 0) {
                setId(res.insertId);
            }
            else {
                alert(`Não foi possível efetuar o registro na base de dados!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}

/*
export const ListaAdvogadoAgendamento = (setAgendamentomento) => {

    ApiAdvogadoAgendamento.ListaAdvogadoAgendamento()
        .then(res => {
            if (res) {
                setAgendamentomento(res);
                console.log(JSON.stringify(res));
            }
            else {
                alert(`Datas para agendamentomento não inseridas!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}*/
