class Contato {
  constructor(id = 0, cpf = '', cep = '', nome = '', dn = '', pai = '', mae = '', rg = '', 
  sexo = '', ec = '', tsv = '', email = '', telefone = '', logradouro= '', numero = '', 
  bairro = '', municipio = '', tema = '', senha = '', mensagem = '', stat = 'novo') {
    this.id = id;
    this.cpf = cpf;
    this.cep = cep;
    this.nome = nome;
    this.dn = dn;
    this.pai = pai;
    this.mae = mae;
    this.rg = rg;
    this.sexo = sexo;
    this.ec = ec;
    this.tsv = tsv;
    this.email = email;
    this.telefone = telefone;
    this.logradouro = logradouro;
    this.numero = numero;
    this.bairro = bairro;
    this.municipio = municipio;    
    this.tema = tema;
    this.senha = senha;
    this.mensagem = mensagem;
    this.stat = stat;
  }
}

export default Contato;