export const dataDate = (data) => {

    let dia = data.substring(0, 2);
    let mes = data.substring(3, 5);
    let ano = data.substring(6);

    return new Date(ano, mes - 1, dia, 0, 0, 0);
}

export const comparaData = (data1, data2) => {

    let dia1 = data1.substring(0, 2);
    let mes1 = data1.substring(3, 5);
    let ano1 = data1.substring(6);

    let dataA = new Date(ano1, mes1 - 1, dia1, 0, 0, 0);

    let dia2 = data2.substring(0, 2);
    let mes2 = data2.substring(3, 5);
    let ano2 = data2.substring(6);

    let dataB = new Date(ano2, mes2 - 1, dia2, 0, 0, 0);

    return (dataA > dataB)? (1) : (-1);
}