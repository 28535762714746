import React from 'react';
import { Typography } from '@material-ui/core';

const Frase = (props) => {
    return <Typography
    variant= {props.variant} 
    align={props.align ? (props.align):('center')}
    style={{ fontSize: props.size, marginTop: props.top, color: 'white' }}        
    >
    {props.texto}
    </Typography>
}

export default Frase;