import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';
import Tabela from '../../tabela/tabela';
import { geraAgenda } from '../../apoio/geraAgenda';

export const TabelaCriaAgenda = (props) => {

    const [dados, setDados] = useState(geraAgenda(props.data));

    const valida = () => {
        let ret = true;
        dados.forEach(element => {
            if (element.data === '') ret = false;
            else if (element.dia === '') ret = false;
            else if (element.hora === '') ret = false;
        });
        return ret;
    }

   
    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (valida()) {
            props.setValue(false);
            props.insere(dados);
        }
        else {
            props.setValue(true);
            alert(`Todos os itens devem ser informados!`);            
        }       
    }

    const removeDados = id => {

        const dadosAtualizados = dados.filter(registro => {
            return registro.id !== id;
        });
        setDados([...dadosAtualizados]);

    }

    const campos = [
        { titulo: 'Data', dado: 'data' },
        { titulo: 'Dia-semana', dado: 'dia' },
        { titulo: 'Hora', dado: 'hora' }
    ]

    return (
        <Grid container spacing={1} >
            <Grid item xs={12} >
                <FraseAlerta texto={'Confira e confirme seus registros'} size={12} cor={'red'} />
            </Grid>
            <Grid item xs={12} >
                <Tabela titulo={'AGENDA'}  campos={campos} dados={dados} removeDados={removeDados}/>
            </Grid>
            <Grid item xs={9} >
                <ButtonForm label={'Inserir registros'} envia={handleSubmit} />
            </Grid>
            <Grid item xs={3} >
                <ButtonForm label={'Sair'} envia={props.setValue} />
            </Grid>
        </Grid>
    );
}