import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonForm } from '../buttonForm';
import Tabela from '../../tabela/tabela';
import { VinculoForm } from './vinculoForm'; 

export const TabelaExibeVinculos = (props) => {

    const [vinculo, setVinculo] = useState('');

    const selecionaDados = id => {       
        const sel = props.vinculos.filter(registro => {
            return registro.id === id;
        });
        setVinculo(sel[0]);
    }

    const removeDados = id => {
        props.removeVinculo(id);
    }


    const campos = [
        { titulo: 'Regime', dado: 'regime' },
        { titulo: 'DataIni', dado: 'dataIni' },
        { titulo: 'DataFim', dado: 'dataFim' }
    ]


    return (
        vinculo ? (
            <Grid container spacing={1} >
                <VinculoForm vinculo={vinculo} 
                idContato={props.contatoId} 
                sexoContato={props.contatoSexo} 
                voltar={() => setVinculo('')} 
                insere={props.insereVinculo} 
                altera={props.alteraVinculo} />

            </Grid>

        ) : (
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <Tabela titulo={'VÍNCULOS'} campos={campos} dados={props.vinculos} selecionaDados={selecionaDados} removeDados={removeDados} />
                    </Grid>
                    <Grid item xs={8} >
                        <ButtonForm label={'Inserir vínculo'} envia={setVinculo} />
                    </Grid>
                    <Grid item xs={4} >
                        <ButtonForm label={'Sair'} envia={props.setValue} />
                    </Grid>
                </Grid>

            )

    );

}