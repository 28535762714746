import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Frase from './frase';
import { CpfForm } from '../form/cpfForm';
import { SenhaForm } from '../form/senhaForm';

export const Login = (props) => {

  const [user, setUser] = useState('');

  return (
    <Container component="main" maxWidth="xs" > 
    <Grid item xs={12}>
      <Frase variant='h6' texto='Acessar Escritório' top={20} />
      </Grid>     
      
      <Paper style={{ padding: 15 }}>
        {
          user ? (
            <SenhaForm user={user} setaLogado={props.setaLogado} />
          ) : (
              <CpfForm setaValue={setUser} />
            )
        }
      </Paper>
      <Grid item xs={12}
        container
        align="flex-end"
        justify="flex-end" >
        <Button
          size='small'
          variant='outlined'
          onClick={props.setaLogin}
          style={{ color: '#76ff03' }} >
          fechar
      </Button>
      </Grid>
      
    </Container>
  );

}
