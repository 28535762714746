import React from 'react';
import { Button } from '@material-ui/core';

const Botao = (props) => {
    return <Button
    variant='contained'
    fullWidth
    style={{fontSize: props.size, 
        marginTop: props.top, 
        color: props.cor, 
        backgroundColor: '#303f9f', 
        borderRadius: 10,
        padding: 10
    }}
    onClick={props.setaValue}        
    >
    {props.texto}
    </Button>
}

export default Botao;