import { enderecoServidor } from '../apoio/enderecoServidor';
const endereco = enderecoServidor();

const ApiClienteVinculo = {

    ListaClienteVinculo: () => {
        return fetch(endereco + '/cliente-vinculo')
            .then(res => res.json());
    },

    ListaClienteVinculoContato: (id) => {
        return fetch(`${endereco}/cliente-vinculo/${id}`)
            .then(res => res.json());
    },

    ListaViewVinculo: () => {
        return fetch(endereco + '/cliente-vinculo-vw')
            .then(res => res.json());
    },

    CriaClienteVinculo: dado => {
        return fetch(endereco + '/cliente-vinculo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 
                'content-type': 'application/json'
                }, 
            body: dado
        })
        .then(res => res.json());
    },

    BuscaClienteVinculo: (id) => {
        return fetch(`${endereco}/cliente-vinculo/${id}`)
            .then(res => res.json());
    },

    BuscaClienteVinculoCpf: (cpf) => {
        return fetch(`${endereco}/cliente-vinculo-cpf/${cpf}`)
            .then(res => res.json());
    },
    
    AlteraClienteVinculo: dado => {
         return fetch(endereco + '/cliente-vinculo', {
             method: 'PATCH',
             headers: {
                 'Accept': 'application/json', 
                 'content-type': 'application/json'
                 }, 
             body: dado
         })
         .then(res => res.json());
     },

    RemoveClienteVinculo: id => {
        return fetch(`${endereco}/cliente-vinculo/${id}`, {
            method: 'DELETE',
            headers: { 'content-type': 'application/json' },
        })
       .then(res => res.json());
    }

}

export default ApiClienteVinculo;