import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { cpfMask } from '../../apoio/cpfMask';
import { validaCpf } from '../../validacao/validaCpf';
import { limpaNum } from '../../apoio/limpaNum';
import { StringForm } from '../stringForm';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';



export const CpfClienteForm = (props) => {

  const [value, setValue] = useState(props.valor ? (props.valor): (''));

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (validaCpf(limpaNum(value))) {
      props.setValue(value);
    }
    else {
      alert(`CPF digitado é inválido!`);
    }
    setValue('');
  }

  return (
    <form>
      <Paper style={{ padding: 10 }} >
        <Grid container spacing={1} >
          <Grid item xs={12} >
            <FraseAlerta texto={'Digite seu CPF'} size={12} cor={'red'} />
          </Grid>

          <Grid item xs={12} >
            <StringForm label='CPF' nome='cpf' type='tel' focus={true} value={cpfMask(value)} setValue={setValue} />
          </Grid>
          <Grid item xs={12} >
            <ButtonForm label={'Confirma'} envia={handleSubmit} />
          </Grid>
        </Grid>
      </Paper>
    </form>

  );
}