import ApiClienteVinculo from '../api/apiClienteVinculo';

export const CriaClienteVinculo = (dado) => {

    ApiClienteVinculo.CriaClienteVinculo(JSON.stringify(dado))
        .then(res => {
            if (res.insertId > 0) {
                console.log(`Vinculo inserido com sucesso!`);
            }
            else {
                alert(`Não foi possível efetuar o registro na base de dados!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}


export const ListaClienteVinculo = (id, setVinculos) => {

    ApiClienteVinculo.ListaClienteVinculo(id)
        .then(res => {
            if (res) {
                setVinculos(res);                
            }
            else {
                alert(`Datas para agendamento não inseridas!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}

export const AlteraClienteVinculo = (dado) => {
    
    ApiClienteVinculo.AlteraClienteVinculo(JSON.stringify(dado))
        .then(res => {
            if (res.serverStatus === 2) {
                console.log("alterado com sucesso ...");               
            }
            else {
                alert(`Não foi possível efetuar o registro na base de dados!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}

export const RemoveClienteVinculo = (id) => {

    ApiClienteVinculo.RemoveClienteVinculo(id)
        .then(res => {
            if (res.id === id) {
                console.log("removido com sucesso")               
            }
            else {
                alert(`Datas para agendamento não inseridas!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}

