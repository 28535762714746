import { enderecoServidor } from '../apoio/enderecoServidor';
const endereco = enderecoServidor();

const ApiAdvogadoAgendamento = {

    ListaAdvogadoAgendamento: () => {
        return fetch(endereco + '/advogado-agendamento')
            .then(res => res.json());
    },

    ListaViewCliente: () => {
        return fetch(endereco + '/advogado-agendamento-vw')
            .then(res => res.json());
    },

    CriaAdvogadoAgendamento: dado => {
        return fetch(endereco + '/advogado-agendamento', {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 
                'content-type': 'application/json'
                }, 
            body: dado
        })
        .then(res => res.json());
    },

    BuscaAdvogadoAgendamento: (id) => {
        return fetch(`${endereco}/advogado-agendamento/${id}`)
            .then(res => res.json());
    },

    BuscaAdvogadoAgendamentoCpf: (cpf) => {
        return fetch(`${endereco}/advogado-agendamento-cpf/${cpf}`)
            .then(res => res.json());
    },
    
    AlteraAdvogadoAgendamento: dado => {
         return fetch(endereco + '/advogado-agendamento', {
             method: 'PATCH',
             headers: {
                 'Accept': 'application/json', 
                 'content-type': 'application/json'
                 }, 
             body: dado
         })
         .then(res => res.json());
     },

    RemoveAdvogadoAgendamento: id => {
        return fetch(`${endereco}/advogado-agendamento/${id}`, {
            method: 'DELETE',
            headers: { 'content-type': 'application/json' },
        })
       .then(res => res.json());
    }

}

export default ApiAdvogadoAgendamento;