import React from 'react';
import { Avatar } from '@material-ui/core';
import img from '../imagem/foto.jpg';

const Foto = (props) => {
    return <Avatar
        alt='logo'        
        src={img}
        onClick={props.setaLogin}
        style={{
            width: props.w,
            height: props.h,
            marginTop: props.top,            
        }}>
    </Avatar>
}

export default Foto;