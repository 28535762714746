import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';
import Tabela from '../../tabela/tabela';
import { formataData } from '../../apoio/formataData';
import { AtendimentoForm } from './atendimentoForm';

export const TabelaExibeContatos = (props) => {

    const [contato, setContato] = useState('');
    
    const selecionaDados = id => {       
        const sel = props.contatos.filter(registro => {
            return registro.id === id;
        });
        setContato(sel[0]);
    }


    const campos = [
        { titulo: 'Tema', dado: 'tema' },
        { titulo: 'Data', dado: 'data' },
        { titulo: 'Hora', dado: 'hora' }
    ]


    return (
        contato ? (
            <Grid container spacing={1} >
                <Grid item xs={12} >
                    <FraseAlerta texto={`${contato.tema} ${formataData(contato.data)} ${contato.hora}`} size={12} cor={'red'} />
                </Grid>   

                <AtendimentoForm contato={contato} contatos = {props.contatos}  setContatos = {props.setContatos} voltar={() => setContato('')} />

            </Grid>

        ) : (
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Listando contatos agendados'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <Tabela titulo={'CONTATOS'} campos={campos} dados={props.contatos} selecionaDados={selecionaDados} />
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Sair'} envia={props.setValue} />
                    </Grid>
                </Grid>

            )
    );

}