import ApiClienteContato from '../api/apiClienteContato';

export const CriaClienteContato = (dado, setId) => {
    
    ApiClienteContato.CriaClienteContato(JSON.stringify(dado))
        .then(res => {
            if (res.insertId > 0) {
                setId(res.insertId);
            }
            else {
                alert(`Não foi possível efetuar o registro na base de dados!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}

export const AlteraClienteContato = (dado) => {
    
    ApiClienteContato.AlteraClienteContato(JSON.stringify(dado))
        .then(res => {
            if (res.serverStatus === 2) {
                console.log("alterado com sucesso ...");               
            }
            else {
                alert(`Não foi possível efetuar o registro na base de dados!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}


