import React, { useState } from "react";
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ButtonForm } from '../buttonForm';
import FraseAlerta from '../../objeto/fraseAlerta';


export const SexoClienteForm = (props) => {

    const [value, setValue] = useState(props.valor ? (props.valor): (''));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (value) {
            props.setValue(value);
        }
        else {
            alert(`Selecione uma das alternativas!`);
        }
        setValue('');
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <form>
            <Paper style={{ padding: 10 }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} >
                        <FraseAlerta texto={'Selecione seu sexo'} size={12} cor={'red'} />
                    </Grid>
                    <Grid item xs={12} >
                        <Box marginLeft={1} >
                            <FormControl component="fieldset" >
                                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} >
                                    <FormControlLabel value="Feminino" control={<Radio />} label="Sou do sexo feminino" />
                                    <FormControlLabel value="Masculino" control={<Radio />} label="Sou do sexo masculino" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <ButtonForm label={'Confirma'} envia={handleSubmit} />
                    </Grid>
                </Grid>
            </Paper>
        </form>

    );
}