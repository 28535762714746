import ApiAdvogadoAgenda from '../api/apiAdvogadoAgenda';

export const verificaAgenda = (setAgendaFim) => {
    ApiAdvogadoAgenda.BuscaAdvogadoAgendaFim()
        .then(result => {
            if (result) {
                setAgendaFim(verifica(result[0].fim));
            }
            else {
                alert(`Agenda não cadastrada!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de autenticação!`);
        }
        );
}

const verifica = (data) => {
    const hoje = new Date().getTime();
    return (data > hoje) ? data : hoje;
}