import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ButtonForm } from '../buttonForm';
import Tabela from '../../tabela/tabela';
import { dataDate } from '../../apoio/dataDate';

export const TabelaVinculo = (props) => {

    const valida = () => {
        let ret = true;
        dados.forEach(element => {
            if (element.registro === '') ret = false;
        });
        return ret;
    }

    const positivo = () => {
        if((dataDate(props.dataFim).getTime() - dataDate(props.dataIni).getTime()) > 0) return true;
        alert(`Data final menor ou igual a data inicial!`);
        return false; 
    } 


    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (valida() && positivo()) {
            props.insere();
            props.voltar();
        }
        else {
            alert(`Todos os itens devem ser informados!`);
        }
    }


    const exibeDados = id => {

        if (id === 'CPF') props.setCpf('');
        else if (id === 'Regime') props.setRegime('');
        else if (id === 'Espécie') props.setEspecie('');
        else if (id === 'Data Inicial') props.setDataIni('');
        else if (id === 'Data Final') props.setDataFim('');
        else if (id === 'Tempo') props.setTempo('');
        else if (id === 'Período') props.setPeriodo('');
        else if (id === 'CBO') props.setCbo('');
        else if (id === 'Atividade') props.setAtividade('');
        else if (id === 'Tipo') props.setTipo('');
        else if (id === 'Local') props.setLocal('');
        else if (id === 'Obs') props.setObs('');    

    }

    const dados = [
        { id: 'Regime', registro: props.regime },
        { id: 'Espécie', registro: props.especie },
        { id: 'Data Inicial', registro: props.dataIni },
        { id: 'Data Final', registro: props.dataFim },
        { id: 'Tempo', registro: props.tempo },
        { id: 'Período', registro: props.periodo },
        { id: 'CBO', registro: props.cbo },
        { id: 'Atividade', registro: props.atividade },
        { id: 'Tipo', registro: props.tipo },
        { id: 'Local', registro: props.local },
        { id: 'Obs', registro: props.obs }        
    ]


    const campos = [
        { titulo: 'Item', dado: 'id' },
        { titulo: 'Registro', dado: 'registro' }
    ]

    return (

        <Grid container spacing={1} >
            <Grid item xs={12} >
                <Tabela titulo={'VÍNCULO'} campos={campos} dados={dados} exibeDados={exibeDados} />
            </Grid>
            <Grid item xs={8} >
                <ButtonForm label={'Confirma'} envia={handleSubmit} />
            </Grid>
            <Grid item xs={4} >
                <ButtonForm label={'Sair'} envia={props.voltar} />
            </Grid>

        </Grid>
    );
}