import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { formataData } from '../apoio/formataData';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Edit from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 300,
    },
});


const MyTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e3f2fd',
        color: 'green',
        fontSize: 10,
    },
    body: {
        fontSize: 10,
    },
}))(TableCell);



const CellDeleta = ({ removeDados, id }) => {

    if (!removeDados) {
        return null
    }

    return (
        <TableCell>
            <Button
                variant='text'
                size="small"
                style={{ color: '#b71c1c' }}//
                onClick={() => {
                    removeDados(id)
                }}
            >
                {<DeleteForever />}
            </Button>
        </TableCell>
    )
}

const CellExibe = ({ exibeDados, id }) => {

    if (!exibeDados) {
        return null
    }

    return (
        <TableCell>
            <Button
                variant='text'
                size="small"
                tooltiptitle={'Reseta'}

                style={{ color: 'green' }}
                onClick={() => {
                    exibeDados(id)
                }}
            >
                {<Edit />}
            </Button>
        </TableCell>
    )
}

const CellSeleciona = ({ selecionaDados, id }) => {

    if (!selecionaDados) {
        return null
    }

    return (
        <TableCell>
            <Button
                variant='text'
                size="small"
                tooltiptitle={'Reseta'}

                style={{ color: 'green' }}
                onClick={() => {
                    selecionaDados(id)
                }}
            >
                {<ArrowForwardIcon />}
            </Button>
        </TableCell>
    )
}




const TituloDeleta = ({ removeDados }) => {
    if (!removeDados) {
        return null
    }

    return <MyTableCell width='5%' >REMOVER</MyTableCell>
}

const TituloExibe = ({ exibeDados }) => {
    if (!exibeDados) {
        return null
    }

    return <MyTableCell width='5%' >ALTERAR</MyTableCell>
}

const TituloSeleciona = ({ selecionaDados }) => {
    if (!selecionaDados) {
        return null
    }

    return <MyTableCell width='5%' >SELECIONAR</MyTableCell>
}

const ajustaData = (campo, dado) => {
    var ret = dado;
    if (campo === 'Data') ret = formataData(dado);
    return ret;
}


const Tabela = props => {
    const { campos, dados, exibeDados, selecionaDados, removeDados } = props

    const classes = useStyles();

    return (

        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead  >
                        <TableRow >
                            <TableCell align="left" style={{color: '#303f9f'}}>
                                <b>{props.titulo}</b>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            {campos.map((campo, index) => (
                                <MyTableCell key={index}>{campo.titulo.toUpperCase()}</MyTableCell>
                            ))}
                            <TituloExibe exibeDados={exibeDados} />
                            <TituloSeleciona selecionaDados={selecionaDados} />
                            <TituloDeleta removeDados={removeDados} />
                        </TableRow>
                    </TableHead>
                    <TableHead />

                    <TableBody >
                        {dados.map(dado => (
                            <TableRow key={dado.id}>
                                {campos.map((campo, index) => (
                                    <TableCell key={index}>{ajustaData(campo.titulo, dado[campo.dado])}</TableCell>
                                ))}
                                <CellExibe id={dado.id} exibeDados={exibeDados} />
                                <CellSeleciona id={dado.id} selecionaDados={selecionaDados} />
                                <CellDeleta id={dado.id} removeDados={removeDados} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </Paper>
    )
}

export default Tabela;