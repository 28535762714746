import React from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export const StringForm = (props) => {

  const handleChange = event => {
    props.setValue(event.target.value);
  }

  return (
    <Grid container spacing={1} >
      <TextField
        variant="outlined"
        required
        fullWidth
        name={props.nome}
        label={props.label}
        type={props.type}
        id={props.nome}
        autoFocus={props.focus}
        value={props.value}
        onChange={handleChange}
      />
    </Grid>
  );
}
