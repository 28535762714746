import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { cpfMask } from '../apoio/cpfMask';
import { validaCpf } from '../validacao/validaCpf';
import { limpaNum } from '../apoio/limpaNum';
import { verificaCpf } from '../acesso/verificaCpf';
import { StringForm } from './stringForm';


export const CpfForm = (props) => {

  const [cpf, setCpf] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (validaCpf(limpaNum(cpf))) {
      verificaCpf(limpaNum(cpf), props.setaValue);
    }
    else {
      alert(`CPF digitado é inválido!`);
    }
    setCpf('');
  }

  return (
    <form>
      <Grid container spacing={1} >        
        <Grid item xs={12} >
          <StringForm label='CPF' nome='cpf' type='tel' focus={true} value={cpfMask(cpf)} setValue={setCpf} />
        </Grid>
        <Grid item xs={12} >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Enviar
        </Button>
        </Grid>

      </Grid>
    </form>
  );
}
