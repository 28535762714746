import ApiAdvogadoAgenda from '../api/apiAdvogadoAgenda';

export const CriaAdvogadoAgenda = (dado, setId) => {

    ApiAdvogadoAgenda.CriaAdvogadoAgenda(JSON.stringify(dado))
        .then(res => {
            if (res.insertId > 0) {
                setId(res.insertId);
            }
            else {
                alert(`Não foi possível efetuar o registro na base de dados!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}


export const ListaAdvogadoAgenda = (setAgendamento) => {

    ApiAdvogadoAgenda.ListaAdvogadoAgenda()
        .then(res => {
            if (res) {
                setAgendamento(res);                
            }
            else {
                alert(`Datas para agendamento não inseridas!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de dados!`);
        }
        );

}




