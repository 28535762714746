import ApiLogin from '../api/apiLogin';

export const verificaSenha = (cpf, senha, setaLogado) => {

    ApiLogin.VerificaSenha(cpf, senha)
        .then(result => {
            if (result === 2) {
                setaLogado();
            }
            else if (result === 1) {
                alert(`Senha digitada está incorreta!`);
            }
            else {
                alert(`Usuário sem permissão de acesso!`);
            }
        })
        .catch(err => {
            console.log(err);
            alert(`Erro de acesso ao servidor de autenticação!`);
        }
        );
}